import L from 'leaflet';
import '~/lib/leaflet.layer.canvasMarkers';
// import {openPopupWindow} from '~/lib/popup-window';
import escapeHtml from 'escape-html';
import {saveAs} from '~/vendored/github.com/eligrey/FileSaver';
import iconFromBackgroundImage from '~/lib/iconFromBackgroundImage';
import {fetch} from '~/lib/xhr-promise';
import {notify} from '~/lib/notifications';
import * as logging from '~/lib/logging';

const FstrPassesMarkers = L.Layer.CanvasMarkers.extend({
        options: {
            filePasses: 'fstr_passes.json',
            scaleDependent: true
        },

        initialize: function(baseUrl, options) {
            L.Layer.CanvasMarkers.prototype.initialize.call(this, null, options);
            this.on('markerclick', this.showPassDescription, this);
            this._baseUrl = baseUrl;
            this.url = baseUrl + this.options.filePasses;
        },

        loadData: function() {
            if (this._downloadStarted) {
                return;
            }
            this._downloadStarted = true;
            fetch(this.url)
                .then(
                    (xhr) => this._loadMarkers(xhr),
                    (e) => {
                        this._downloadStarted = false;
                        logging.captureException(e, 'failed to get FSTR passes');
                        notify('Failed to get FSTR passes data');
                    }
                );
        },

        onAdd: function(map) {
            L.Layer.CanvasMarkers.prototype.onAdd.call(this, map);
            this.loadData();
        },

        _makeTooltip: function(marker) {
            var properties = marker.properties,
                toolTip = properties.grade || '';
            // if (toolTip && properties.elevation) {
            //     toolTip += ', ';
            // }
            // toolTip += properties.elevation || '';
            if (toolTip) {
                toolTip = ' (' + toolTip + ')';
            }
            toolTip = (properties.name || 'без названия') + toolTip;
            // toolTip = (properties.is_summit ? 'Вершина ' : 'Перевал ') + toolTip;
            toolTip = 'Перевал ' + toolTip;
            return toolTip;
        },

        _passToGpx: function(marker) {
            let label = marker.tooltip;
            if (typeof label === 'function') {
                label = label(marker);
            }
            label = escapeHtml(label);
            const gpx = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
                <gpx xmlns="http://www.topografix.com/GPX/1/1"
                     creator="http://nakarte.me"
                     xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                     xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd"
                     version="1.1">
                    <wpt lat="${marker.latlng.lat.toFixed(6)}" lon="${marker.latlng.lng.toFixed(6)}">
                        <name>${label}</name>
                    </wpt>
                </gpx>
            `;
            var filename = marker.properties.name || 'Без названия';
            saveAs(new Blob([gpx], {type: 'application/gpx+xml'}), filename + '.gpx', true);
        },

        _passToKml: function(marker) {
            let label = marker.tooltip;
            if (typeof label === 'function') {
                label = label(marker);
            }
            label = escapeHtml(label);
            const kml = `<?xml version="1.0" encoding="UTF-8"?>
                <kml xmlns="http://www.opengis.net/kml/2.2">
                    <Placemark>
                        <name>${label}</name>
                        <Point>
                            <coordinates>
                                ${marker.latlng.lng.toFixed(6)},${marker.latlng.lat.toFixed(6)},0
                            </coordinates>
                        </Point>
                    </Placemark>
                </kml>
            `;
            var filename = marker.properties.name || 'Без названия';
            saveAs(new Blob([kml], {type: 'application/vnd.google-earth.kml+xml'}), filename + '.kml', true);
        },

        _makeIcon: function(marker) {
            var className;
            className = 'fstr-pass-marker fstr-pass-marker-' + marker.properties.grade_norm;
            return iconFromBackgroundImage(className);
        },

        _loadMarkers: function(xhr) {
            var markers = [],
                features = JSON.parse(xhr.response),
                feature, i, marker;
            for (i = 0; i < features.length; i++) {
                feature = features[i];
                marker = {
                    latlng: {
                        lat: feature.latitude,
                        lng: feature.longitude,
                    },
                    label: feature.name || "",
                    icon: this._makeIcon,
                    tooltip: this._makeTooltip.bind(this),
                    properties: feature
                };
                markers.push(marker);
                // FIXME: Remove
                // if (feature.details.length > 1) {
                //     console.log(feature.details.length, feature.latitude, feature.longitude);
                // }
            }
            this.addMarkers(markers);
            this._dataLoaded = true;
            this.fire('data-loaded');
        },

        showPassDescription: function(e) {
            if (!this._map) {
                return;
            }
            const properties = e.marker.properties;
            const latLng = e.marker.latlng;
            //     url = 'https://fstr.ru/passes/Passes/' + properties.id;
            // let altnames = '',
            //     connects = '',
            //     comments = '';
            // if (properties.altnames) {
            //     altnames = `
            //         <tr>
            //             <td>Другие названия</td>
            //             <td>${properties.altnames}</td>
            //         </tr>`;
            // }

            // if (!properties.is_summit) {
            //     connects = `
            //         <tr>
            //             <td>Соединяет</td>
            //             <td>${properties.connects || "неизвестнo"}</td>
            //         </tr>`;
            // }

            // if (properties.comments) {
            //     for (let comment of properties.comments) {
            //         let user = '';
            //         if (comment.user) {
            //             user = `<span class="fstr-passes-description-comment-author">${comment.user}:</span>`;
            //         }
            //         comments += `<p class="fstr-passes-description-comment">${user}${comment.content}</p>`;
            //     }
            //     comments = `
            //         <tr>
            //             <td>Комментарии</td>
            //             <td>${comments}</td>
            //         </tr>`;
            // }
            // let reports;
            // if (properties.reports_total) {
            //     reports =
            //         `<br>Отчетов: ${properties.reports_total}, ` +
            //         `с фото: ${properties.reports_photo || 0}, ` +
            //         `с описанием: ${properties.reports_tech || 0}`;
            // } else {
            //     reports = '<br>Отчетов нет';
            // }
            const variantsLines = properties.details.map((variant) => `
                <tr>
                    <td>${variant.number}</td>
                    <td>${properties.name}</td>
                    <td>${variant.altnames}</td>
                    <td>${variant.elevation}</td>
                    <td>${properties.grade}</td>
                    <td>${variant.surface_type}</td>
                    <td>${variant.connects}</td>
                    <td>${variant.first_visit}</td>
                    <td>${variant.comment}</td>
                </tr>
            `);

            let description = `
                <table class="pass-details">
                    <tr>
                        <td>${properties.is_coordinate_exact ? "Координаты точные" : "Координаты приблизительные"}</td>
                        <td>
                            <table class="coords">
                                <tr class="header">
                                    <td>Широта</td>
                                    <td>Долгота</td>
                                </tr>
                                <tr>
                                    <td>${latLng.lat.toFixed(5)}</td>
                                    <td>${latLng.lng.toFixed(5)}</td>
                                    <td><a id="fstr-pass-gpx" title="Сохранить">gpx</a></td>
                                    <td><a id="fstr-pass-kml" title="Сохранить">kml</a></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <table class="fstr-pass-variants">
                    <tr>
                        <th>Номер внутри региона</th>
                        <th>Название</th>
                        <th>Другие названия</th>
                        <th>Высота</th>
                        <th>Категория</th>
                        <th>Характеристика</th>
                        <th>Что соединяет и как расположен</th>
                        <th>Руководитель, город, год первопрохождения</th>
                        <th>Дополнительная информация</th>
                    </tr>
                    ${variantsLines.join('')}
                </table>`;
            // console.log(description);
            this._map.openPopup(description, latLng, {maxWidth: 1500});
            // document.getElementById('fstr-pass-link').onclick = function() {
            //     openPopupWindow(url, 780, 'fstr-details');
            //     return false;
            // };
            document.getElementById('fstr-pass-gpx').onclick = function() {
                this._passToGpx(e.marker);
                return false;
            }.bind(this);
            document.getElementById('fstr-pass-kml').onclick = function() {
                this._passToKml(e.marker);
                return false;
            }.bind(this);
        }
    }
);

export {FstrPassesMarkers};
